<template>
    <div
        v-if="authStore.impersonate || $can('impersonate', 'users')"
        class="absolute top-0 sm:left-1/2 sm:-translate-x-1/2 w-full sm:w-auto bg-amber-500 text-black z-50 sm:p-1 shadow rounded rounded-t-none transform transition-transform"
        :class="{'translate-y-0':authStore.impersonate,'-translate-y-10':!authStore.impersonate}">
        <div class="flex text-xs font-bold justify-center">
            <div>
                {{
                    $t('users.impersonateBanner', {
                        organisation_name: authStore.userData?.organisation_name,
                        fullname: authStore.userData?.fullname
                    })
                }}
            </div>
            <div class="ms-3 cursor-pointer">
                <span
                    v-if="!loading"
                    @click="leaveImpersonation">{{ $t('users.leaveImpersonationLink') }}
                </span>
                <span v-else>
                    <LoadingSpinner size="sm" />
                </span>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useNuxtApp} from "nuxt/app"
import {useAuthStore} from "@/store/auth"

const authStore = useAuthStore()
const notification = useNotification()
const {t: $t} = useI18n()
const {$apiRoute} = useNuxtApp()

const loading = ref(false)

function leaveImpersonation() {
    loading.value = true
    $lara.get($apiRoute('spa.leaveImpersonation', {user: authStore.userData.id}))
        .then(response => {
            loading.value = false
            if (response?.error) return notification.error($t('users.leaveImpersonationError'))
            if (response === 'disabled') {
                notification.success($t('users.leaveImpersonationSuccess'))
                authStore.successfulLogin()
            }
        }).catch(() => {
            notification.error($t('users.leaveImpersonationError'))
        }).finally(() => {
            loading.value = false
        })
}
</script>