import {$getModuleColor, $getModuleIcon} from "./appModules"

export const sidebarMenu: SidebarMenu = [
    {
        title: 'default',
        menu: [
            {
                title: 'menu.dashboard',
                path: '/',
                icon: $getModuleIcon('dashboard'),
                module: 'dashboard',
            },
            {
                title: 'menu.users',
                path: 'users',
                icon: $getModuleIcon('users'),
                color: $getModuleColor('users'),
                module: 'users',
                ability: ['viewAny', 'users']
            },
            {
                title: 'menu.settings',
                path: 'settings',
                icon: $getModuleIcon('settings'),
                color: $getModuleColor('settings'),
                module: 'settings',
                children: [
                    {
                        title: 'menu.organisations',
                        path: 'organisations',
                        module: 'organisations',
                        ability: ['update', 'organisations']
                    },
                    {
                        title: 'menu.loginProviders',
                        path: 'settings-loginProviders',
                        ability: ['update', 'loginProviders']
                    },
                    {
                        title: 'menu.mailTemplates',
                        path: 'settings-mailTemplates',
                        ability: ['viewAny', 'mailTemplates']
                    },
                    {
                        title: 'menu.mails',
                        path: 'settings-mails',
                        ability: ['viewAny', 'mails'],
                        feature: 'mailLogs'
                    }
                ]
            }
        ]
    },
    // {
    //     title: 'Abschnitt 2',
    //     menu: [
    //         {
    //             title: 'Element 1',
    //             initial: 'E1',
    //             path: '/',
    //
    //         },
    //         {
    //             title: 'Element 2',
    //             path: '/',
    //             initial: 'E2',
    //         },
    //     ]
    // }
]